import React, { useEffect, useState } from 'react';
import { Spinner, Tab } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import ChartLabel from './chartLabel';

const AverageStageAppCountChart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState(null);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const fetchData = async () => {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/analytics/application-status-distribution`;

    try {
      const response = await fetch(URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      const body = await response.json();
      const data = body.data;
      setChartData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Could not fetch chart data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const aiAnswerAccepted = chartData?.find(
    ({ status }) => status === 'AI_ANSWERS_ACCEPTED'
  )?.count;
  const preAssessmentPassed = chartData?.find(
    ({ status }) => status === 'PRE_ASSESSMENT_PASSED'
  )?.count;
  const accepted = chartData?.find(
    ({ status }) => status === 'ACCEPTED'
  )?.count;
  const profileAnalyzed = chartData?.find(
    ({ status }) => status === 'PROFILE_ANALYZED'
  )?.count;
  const rejected = chartData?.find(
    ({ status }) => status === 'REJECTED'
  )?.count;

  const data = {
    labels: ['Application stages'],
    datasets: [
      {
        label: 'Profile Analyzed',
        data: [profileAnalyzed],
        backgroundColor: 'rgb(183, 35, 173)'
      },

      {
        label: 'AI Answers Accepted',
        data: [aiAnswerAccepted],
        backgroundColor: 'rgb(38, 199, 170)'
      },
      {
        label: 'Pre Assesment Passed',
        data: [preAssessmentPassed],
        backgroundColor: 'rgb(20, 86, 80)'
      },

      {
        label: 'Accepted',
        data: [accepted],
        backgroundColor: 'rgb(56, 184, 86)'
      },
      {
        label: 'Rejected',
        data: [rejected],
        backgroundColor: 'rgb(245, 79, 82)'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: 'rgba(89, 59, 219,0.1)',
          drawBorder: true
        }
      },
      y: {
        // type:'logarithmic',
        grid: {
          color: 'rgba(89, 59, 219,0.1)',
          drawBorder: true,
          display: true
        }
      }
    }
  };

  return (
    <>
      <style>
        {`
          /* Only adding new styles, not modifying existing layout */
          .card {
            background: white;
            border-radius: 12px;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.03);
            margin-bottom: 24px;
          }
  
          .card-header h4 {
            font-size: 1.25rem;
            font-weight: 600;
            color: #1a1a1a;
            letter-spacing: -0.02em;
            display: flex;
            align-items: center;
            gap: 12px;
          }
  
          .status-badge {
            display: inline-flex;
            align-items: center;
            gap: 6px;
            padding: 4px 12px;
            border-radius: 20px;
            font-size: 0.75rem;
            font-weight: 500;
            background: rgba(72, 133, 237, 0.08);
            color: #4885ED;
            margin-left: 12px;
          }
  
          .stage-label {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 6px 12px;
            border-radius: 6px;
            font-size: 0.875rem;
            font-weight: 500;
            transition: all 0.2s ease;
          }
  
          .stage-label:hover {
            background: rgba(226, 232, 240, 0.3);
          }
  
          .stage-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            display: inline-block;
          }
  
          /* Enhanced loading spinner */
          .spinner-border {
            color: #4885ED !important;
            opacity: 0.6;
          }
  
          /* Better empty state */
          .no-data {
            text-align: center;
            color: #64748b;
            font-weight: 500;
          }
        `}
      </style>
  
      <Tab.Container defaultActiveKey="Daily">
        <div className="card" style={{ height: '30rem' }}>
          <div className="card-header border-0 pb-0 flex-wrap">
            <h4 className="fs-20 font-w500 mb-1">
              Average Stage Application Count
              <span className="status-badge">
                <i className="fas fa-chart-bar" style={{ fontSize: '10px' }}></i>
                Applications
              </span>
            </h4>
          </div>
  
          <div className="card-body">
            <div className="pb-4 d-flex flex-wrap justify-content-between">
              <div className="stage-label">
                <span className="stage-dot" style={{ background: '#B723AD' }}></span>
                <span>Profile Analyzed</span>
              </div>
              <div className="stage-label">
                <span className="stage-dot" style={{ background: '#26C7AA' }}></span>
                <span>AI Answers Accepted</span>
              </div>
              <div className="stage-label">
                <span className="stage-dot" style={{ background: '#145650' }}></span>
                <span>Pre Assessment Passed</span>
              </div>
              <div className="stage-label">
                <span className="stage-dot" style={{ background: '#38B856' }}></span>
                <span>Accepted</span>
              </div>
              <div className="stage-label">
                <span className="stage-dot" style={{ background: '#F54F52' }}></span>
                <span>Rejected</span>
              </div>
            </div>
  
            <Tab.Content>
              <div style={{ height: '19rem' }}>
                {isLoading ? (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <>
                    {chartData ? (
                      <Bar options={options} data={data} />
                    ) : (
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <h5 className="no-data">No Data Found</h5>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};

export default AverageStageAppCountChart;
