import React from 'react';
import { OverlayTrigger, Spinner } from 'react-bootstrap';
import { IoIosInformationCircle } from 'react-icons/io';

const WindowCard = ({ data, isLoading }) => {
  const { value1, title1, value2, title2, value3, title3, value4, title4 } =
    data;
  const { info1, info2, info3, info4 } = data;

  // Modern gradient colors
  const gradients = [
    'linear-gradient(135deg, #7B6EF6 0%, #A39BF5 100%)',
    'linear-gradient(135deg, #6DBBAB 0%, #8ED4C7 100%)',
    'linear-gradient(135deg, #5B93D5 0%, #8BB4E5 100%)',
    'linear-gradient(135deg, #B784D1 0%, #D1AAE3 100%)'
  ];

  const CardItem = ({ value, title, info, index }) => {
    return (
      <div className={`col-sm-6 p-3`}>
        <div className="stat-card" style={{
          background: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(8px)',
          borderRadius: '16px',
          padding: '1.5rem',
          border: '1px solid rgba(255, 255, 255, 0.8)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
          transition: 'all 0.3s ease',
          position: 'relative',
          overflow: 'hidden',
        }}>
          {/* Decorative gradient circle in background */}
          <div style={{
            position: 'absolute',
            right: '-20px',
            top: '-20px',
            width: '100px',
            height: '100px',
            background: gradients[index],
            opacity: '0.1',
            borderRadius: '50%',
            zIndex: 0
          }} />

          <div className="d-flex justify-content-between align-items-start" style={{ position: 'relative', zIndex: 1 }}>
            <div>
              <h2 style={{
                fontSize: '2rem',
                fontWeight: '700',
                marginBottom: '0.5rem',
                backgroundImage: gradients[index],
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                color: 'transparent',
              }}>
                {isLoading ? (
                  <Spinner
                    style={{
                      borderWidth: '0.15rem',
                      width: '1.5rem',
                      height: '1.5rem'
                    }}
                    variant="secondary"
                  />
                ) : value === undefined ? (
                  'N/A'
                ) : (
                  value
                )}
              </h2>
              <p style={{
                fontSize: '0.95rem',
                color: '#64748B',
                fontWeight: '500',
                marginBottom: 0,
                letterSpacing: '0.02em'
              }}>
                {title}
              </p>
            </div>

            <OverlayTrigger
              overlay={
                <div style={{
                  maxWidth: '15rem',
                  background: 'rgba(255, 255, 255, 0.95)',
                  backdropFilter: 'blur(8px)',
                  borderRadius: '12px',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                  padding: '1rem',
                  fontSize: '0.875rem',
                  color: '#475569',
                  border: '1px solid rgba(255, 255, 255, 0.8)',
                  marginTop: '0.5rem'
                }}>
                  {info}
                </div>
              }
              placement="bottom"
            >
              <div className="info-icon" style={{
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                padding: '0.5rem',
                borderRadius: '50%',
                background: 'rgba(123, 110, 246, 0.1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <IoIosInformationCircle 
                  size={22} 
                  style={{
                    backgroundImage: gradients[index],
                    WebkitBackgroundClip: 'text',
                    backgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card" style={{
      background: 'linear-gradient(135deg, #f8f9ff 0%, #f3f6ff 100%)',
      border: 'none',
      borderRadius: '20px',
      boxShadow: '0 8px 24px rgba(149, 157, 165, 0.1)',
    }}>
      <div className="card-body p-4">
        <div className="row g-3">
          <CardItem value={value1} title={title1} index={0} info={info1} />
          <CardItem value={value2} title={title2} index={1} info={info2} />
          <CardItem value={value3} title={title3} index={2} info={info3} />
          <CardItem value={value4} title={title4} index={3} info={info4} />
        </div>
      </div>
    </div>
  );
};

export default WindowCard;
