import React, { useEffect, useState } from 'react';
import { Spinner, Tab } from 'react-bootstrap';
import ChartLabel from './chartLabel';
import ChartSelector from './chartSelector';
import { Line } from 'react-chartjs-2';

const ApplicationTrendsChart = () => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState('daily');

  const selectorData = [
    { 
      title: '24H', 
      value: 'daily', 
      icon: '⏰', 
      label: 'Daily View'
    },
    { 
      title: '7D', 
      value: 'weekly', 
      icon: '📅', 
      label: 'Weekly View'
    },
    { 
      title: '30D', 
      value: 'monthly', 
      icon: '📊', 
      label: 'Monthly View'
    }
  ];

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const fetchData = async () => {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/
		${orgId}/analytics/applications-count/${timePeriod}`;

    try {
      const response = await fetch(URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      const body = await response.json();
      const data = body.data;
      setChartData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Could not fetch chart data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [timePeriod]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    if (timePeriod == 'weekly') {
      return 'week ' + dateString.split('-')[1];
    } else if (timePeriod === 'monthly') {
      return month;
    }

    return `${day} ${month}`;
  }

  const countData = chartData.map(({ count }) => count);
  const timeData = chartData.map(({ period }) => formatDate(period));

  const data = {
    defaultFontFamily: '"Inter", sans-serif',
    labels: timeData,
    datasets: [
      {
        label: 'Applications',
        backgroundColor: 'rgba(72, 133, 237, 0.05)',
        borderColor: '#4885ED',
        data: countData,
        borderWidth: 3,
        tension: 0.4,
        fill: true,
        pointBackgroundColor: '#FFFFFF',
        pointBorderColor: '#4885ED',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6,
        pointHoverBorderWidth: 3,
        pointHoverBackgroundColor: '#4885ED',
        pointHoverBorderColor: '#FFFFFF'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 0.98)',
        titleColor: '#1a1a1a',
        bodyColor: '#1a1a1a',
        titleFont: {
          size: 13,
          weight: '600',
          family: '"Inter", sans-serif'
        },
        bodyFont: {
          size: 12,
          family: '"Inter", sans-serif'
        },
        padding: 12,
        boxPadding: 6,
        usePointStyle: true,
        borderColor: 'rgba(220, 220, 220, 0.9)',
        borderWidth: 1,
        boxWidth: 8,
        boxHeight: 8,
        caretSize: 6,
        cornerRadius: 8,
      }
    },
    scales: {
      y: {
        grid: {
          color: 'rgba(200, 200, 200, 0.08)',
          drawBorder: false,
          lineWidth: 1
        },
        ticks: {
          font: {
            size: 12,
            family: '"Inter", sans-serif',
            weight: '500'
          },
          color: '#6E6E6E',
          padding: 10
        },
        border: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 12,
            family: '"Inter", sans-serif',
            weight: '500'
          },
          color: '#6E6E6E',
          padding: 10
        },
        border: {
          display: false
        }
      }
    }
  };

  return (
    <>
      <style>
        {`
          .card-header {
            width: 100%;
          }

          .header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            gap: 24px;
          }

          .header-content {
            flex: 1;
          }

          .header-title {
            font-size: 1.5rem;
            font-weight: 600;
            color: #1a1a1a;
            letter-spacing: -0.02em;
            margin-bottom: 6px;
            display: flex;
            align-items: center;
            gap: 12px;
          }

          .header-subtitle {
            color: #64748b;
            font-size: 0.95rem;
            display: flex;
            align-items: center;
            gap: 16px;
            margin: 0;
          }

          .status-badge {
            display: inline-flex;
            align-items: center;
            gap: 6px;
            padding: 4px 12px;
            border-radius: 20px;
            font-size: 0.75rem;
            font-weight: 500;
            background: rgba(72, 133, 237, 0.08);
            color: #4885ED;
            border: 1px solid rgba(72, 133, 237, 0.2);
          }

          .trend-badge {
            display: inline-flex;
            align-items: center;
            gap: 4px;
            padding: 4px 10px;
            border-radius: 6px;
            font-size: 0.75rem;
            font-weight: 500;
            background: rgba(34, 197, 94, 0.08);
            color: #22C55E;
            border: 1px solid rgba(34, 197, 94, 0.2);
          }

          .period-selector {
            background: rgba(249, 250, 251, 0.8);
            backdrop-filter: blur(8px);
            padding: 4px;
            border-radius: 12px;
            display: inline-flex;
            gap: 4px;
            border: 1px solid rgba(226, 232, 240, 0.8);
          }

          .period-selector .tab-btn {
            border: none;
            padding: 8px 16px;
            border-radius: 8px;
            font-size: 0.875rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 6px;
            transition: all 0.2s ease;
            position: relative;
            background: transparent;
            color: #64748b;
            min-width: 90px;
            justify-content: center;
          }

          .period-selector .tab-btn:hover {
            background: rgba(255, 255, 255, 0.9);
            color: #4885ED;
            transform: translateY(-1px);
          }

          .period-selector .tab-btn.active {
            background: #4885ED;
            color: white;
            box-shadow: 0 4px 12px rgba(72, 133, 237, 0.2);
          }

          .period-selector .tab-btn .icon {
            font-size: 14px;
          }

          .period-selector .tab-btn .tooltip {
            position: absolute;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(0, 0, 0, 0.8);
            color: white;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
            opacity: 0;
            transition: all 0.2s ease;
            pointer-events: none;
            white-space: nowrap;
            z-index: 10;
          }

          .period-selector .tab-btn:hover .tooltip {
            opacity: 1;
            bottom: -35px;
          }

          .chart-container {
            transition: all 0.3s ease;
          }

          .chart-container:hover {
            box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
          }

          .badge.bg-primary-subtle {
            background-color: rgba(72, 133, 237, 0.1) !important;
            color: #4885ED !important;
            font-weight: 500;
          }

          .metrics-badge {
            display: inline-flex;
            align-items: center;
            gap: 6px;
            padding: 4px 8px;
            border-radius: 6px;
            font-size: 0.75rem;
            font-weight: 500;
            background: rgba(72, 133, 237, 0.1);
            color: #4885ED;
          }

          .metrics-badge.success {
            background: rgba(34, 197, 94, 0.1);
            color: #22C55E;
          }

          .trend-indicator {
            padding: 2px 8px;
            border-radius: 4px;
            background: rgba(25, 135, 84, 0.1);
            font-weight: 500;
          }

          .chart-wrapper {
            transition: opacity 0.2s ease;
          }

          .rounded-xl {
            border-radius: 1rem;
          }

          .shadow-sm {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          }

          .chart-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px 0;
            border-top: 1px solid rgba(226, 232, 240, 0.8);
            margin-top: 16px;
          }

          .chart-label {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            padding: 6px 12px;
            border-radius: 8px;
            background: rgba(72, 133, 237, 0.08);
            color: #4885ED;
            font-size: 0.875rem;
            font-weight: 500;
          }

          .chart-label .dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #4885ED;
          }
        `}
      </style>

      <div className="chart-container bg-white rounded-xl shadow-sm">
        <Tab.Container defaultActiveKey={selectorData[0].value}>
          <div className="card border-0">
            <div className="card-header bg-transparent border-0 pt-5 px-5 pb-0">
              <div className="header-wrapper">
                <div className="header-content">
                  <h4 className="header-title">
                    Application Insights
                    <span className="status-badge">
                      <i className="fas fa-circle text-primary" style={{ fontSize: '8px' }}></i>
                      Real-time Data
                    </span>
                  </h4>
                  <p className="header-subtitle">
                    Analytics and performance metrics
                  </p>
                </div>

                <div className="period-selector">
                  {selectorData.map((item) => (
                    <button
                      key={item.value}
                      onClick={() => setTimePeriod(item.value)}
                      className={`tab-btn ${timePeriod === item.value ? 'active' : ''}`}
                    >
                      <span className="icon">{item.icon}</span>
                      <span className="label">{item.title}</span>
                      <span className="tooltip">{item.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            
            <div className="card-body px-4 pt-3">
              <Tab.Content>
                <div className="chart-wrapper" style={{ height: '300px' }}>
                  {isLoading ? (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <Spinner
                        animation="border"
                        className="text-primary opacity-50"
                        size="lg"
                      />
                    </div>
                  ) : (
                    <>
                      {chartData?.length !== 0 ? (
                        <Line options={options} data={data} />
                      ) : (
                        <div className="h-100 d-flex align-items-center justify-content-center">
                          <div className="text-center">
                            <p className="text-gray-500 mb-0">No Data Available</p>
                            <small className="text-gray-400">
                              Try selecting a different time period
                            </small>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Tab.Content>

              <div className="chart-footer">
                <div className="chart-label">
                  <span className="dot"></span>
                  <span>Applications</span>
                </div>
              </div>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default ApplicationTrendsChart;
