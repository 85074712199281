import React, { useEffect, useState } from 'react';
import { Spinner, Tab } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';

const AverageStageDurationChart = ({}) => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const fetchData = async () => {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/analytics/application-average-status-time`;

    try {
      const response = await fetch(URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      const body = await response.json();
      const data = body.data;
      setChartData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Could not fetch pie data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const aiAnswerSubmitted = chartData?.find(
    ({ status }) => status === 'AI_ANSWERS_SUBMITTED'
  )?.averageDuration;
  const applicationSubmitted = chartData?.find(
    ({ status }) => status === 'APPLICATION_SUBMITTED'
  )?.averageDuration;
  const aiAnswerAccepted = chartData?.find(
    ({ status }) => status === 'AI_ANSWERS_ACCEPTED'
  )?.averageDuration;
  const preAssessmentPassed = chartData?.find(
    ({ status }) => status === 'PRE_ASSESSMENT_PASSED'
  )?.averageDuration;
  const profileAnalyzed = chartData?.find(
    ({ status }) => status === 'PROFILE_ANALYZED'
  )?.averageDuration;
  const rejected = chartData?.find(
    ({ status }) => status === 'REJECTED'
  )?.averageDuration;

  const data = {
    labels: ['Application Stages'],
    datasets: [
      {
        label: 'Application Submitted',
        data: [applicationSubmitted],
        backgroundColor: 'rgb(72, 133, 237)'
      },
      {
        label: 'Profile Analyzed',
        data: [profileAnalyzed],
        backgroundColor: 'rgb(183, 35, 173)'
      },
      {
        label: 'AI Answers Submitted',
        data: [aiAnswerSubmitted],
        backgroundColor: 'rgb(20, 86, 80)'
      },

      {
        label: 'AI Answers Accepted',
        data: [aiAnswerAccepted],
        backgroundColor: 'rgb(38, 199, 170)'
      },

      {
        label: 'Pre Assesment Passed',
        data: [preAssessmentPassed],
        backgroundColor: 'rgb(56, 184, 86)'
      },
      {
        label: 'Rejected',
        data: [rejected],
        backgroundColor: 'rgb(245, 79, 82)'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.98)',
        titleColor: '#1a1a1a',
        bodyColor: '#1a1a1a',
        titleFont: {
          size: 13,
          weight: '600',
          family: '"Inter", sans-serif'
        },
        bodyFont: {
          size: 12,
          family: '"Inter", sans-serif'
        },
        padding: 12,
        boxPadding: 6,
        usePointStyle: true,
        borderColor: 'rgba(220, 220, 220, 0.9)',
        borderWidth: 1,
        cornerRadius: 8,
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          font: {
            size: 12,
            family: '"Inter", sans-serif'
          },
          color: '#64748b'
        }
      },
      y: {
        type: 'logarithmic',
        grid: {
          color: 'rgba(226, 232, 240, 0.5)',
          drawBorder: false
        },
        ticks: {
          font: {
            size: 12,
            family: '"Inter", sans-serif'
          },
          color: '#64748b',
          padding: 8
        }
      }
    }
  };

  return (
    <>
      <style>
        {`
          .duration-card {
            background: white;
            border-radius: 16px;
            box-shadow: 0 4px 24px rgba(0, 0, 0, 0.03);
            height: 30rem;
          }

          .duration-header {
            padding: 24px 24px 16px;
            border-bottom: 1px solid rgba(226, 232, 240, 0.8);
          }

          .duration-title {
            font-size: 1.25rem;
            font-weight: 600;
            color: #1a1a1a;
            letter-spacing: -0.02em;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            gap: 12px;
          }

          .duration-badge {
            display: inline-flex;
            align-items: center;
            gap: 6px;
            padding: 4px 12px;
            border-radius: 20px;
            font-size: 0.75rem;
            font-weight: 500;
            background: rgba(72, 133, 237, 0.08);
            color: #4885ED;
          }

          .stage-labels {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            padding: 16px 24px;
          }

          .stage-label {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 6px 12px;
            border-radius: 6px;
            font-size: 0.875rem;
            font-weight: 500;
            background: rgba(226, 232, 240, 0.3);
            transition: all 0.2s ease;
          }

          .stage-label:hover {
            background: rgba(226, 232, 240, 0.5);
          }

          .chart-area {
            padding: 0 24px;
            height: 19rem;
          }

          .loading-state {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .spinner-border {
            color: #4885ED !important;
            opacity: 0.6;
          }
        `}
      </style>

      <Tab.Container defaultActiveKey="Daily">
        <div className="duration-card">
          <div className="duration-header">
            <h4 className="duration-title">
              Average Stage Durations
              <span className="duration-badge">
                <i className="fas fa-clock" style={{ fontSize: '10px' }}></i>
                In Minutes
              </span>
            </h4>
          </div>

          <div className="stage-labels">
            <div className="stage-label">
              <span style={{ color: '#4885ED' }}>●</span>
              <span>Application Submitted</span>
            </div>
            <div className="stage-label">
              <span style={{ color: '#B723AD' }}>●</span>
              <span>Profile Analyzed</span>
            </div>
            <div className="stage-label">
              <span style={{ color: '#145650' }}>●</span>
              <span>AI Answers Submitted</span>
            </div>
            <div className="stage-label">
              <span style={{ color: '#26C7AA' }}>●</span>
              <span>AI Answers Accepted</span>
            </div>
            <div className="stage-label">
              <span style={{ color: '#38B856' }}>●</span>
              <span>Pre Assessment Passed</span>
            </div>
            <div className="stage-label">
              <span style={{ color: '#F54F52' }}>●</span>
              <span>Rejected</span>
            </div>
          </div>

          <div className="chart-area">
            {isLoading ? (
              <div className="loading-state">
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                {chartData ? (
                  <Bar options={options} data={data} />
                ) : (
                  <div className="loading-state">
                    <h5 className="text-muted">No Data Found</h5>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Tab.Container>
    </>
  );
};

export default AverageStageDurationChart;
